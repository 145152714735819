.react-select__control {
  color: #1a085a;
}
.react-select__control {
  border-radius: 0px !important;
  border-bottom: solid #8bcac8 3px !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  height: 45px;
}
.react-select__control:focus {
  box-shadow: none !important;
  border-bottom: solid #8bcac8 3px !important;
}
.react-select__control:hover {
  box-shadow: none !important;
  border-bottom: solid #8bcac8 3px !important;
}
.react-select__control--is-focused {
  box-shadow: none !important;
  border-style: none !important;
  border-bottom: solid #8bcac8 3px !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  height: 45px;
}
.react-select__indicator {
  fill: #1a085a;
}

div.react-select__placeholder {
  color: #1a085a !important;
  font-size: 16px;
}

.react-select__single-value {
  color: #1a085a !important;
  font-weight: bold;
}
.react-select__single-value:hover {
  color: #1a085a !important;
  font-weight: bold;
}

.cSelect2134556uyio {
  margin-top: 5%;
}

.css-1uccc91-singleValue {
  color: #1a085a !important;
  font-weight: bold;
}

.react-select__indicator-separator {
  background-color: transparent !important;
}
.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}
