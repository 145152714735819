.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1a085a;
}
/* Internet Explorer 9-11 */

.feedCol h1 {
  font-size: calc(20px + 2vmin);
  color: rgb(32, 56, 100);
  font-weight: bolder;
  letter-spacing: 0.01em;
}

.feedCol h2 {
  font-size: calc(15px + 2vmin);
  color: rgb(32, 56, 100);
  min-height: 20vh;
  justify-content: center;
  align-items: center;
}

.noteFormSM {
  font-size: 0.9em;
}
.noteFormSM::after {
  margin-top: 10px !important;
}
.feedCol h3 {
  font-size: calc(10px + 2vmin);
  color: rgb(32, 56, 100);
  font-weight: normal;
  letter-spacing: 0.01em;
}

.headerCardSM {
  font-size: calc(25px + 2vmin);
  color: #1a085a;
  min-height: 15vh;
  justify-content: center;
  align-items: center;
  background-color: #8bcac8;
}
/* ie9 ie11
_::selection,
.selector .headerCardSM {
}
*/

.headerCardSM h1 {
  font-size: calc(32px + 2vmin);
  font-weight: bold;
  letter-spacing: 0.001em;
  display: table-cell;
}

.btnFeed {
  font-size: calc(35px + 2vmin);
  background-color: #8bcac8;
  color: #1a085a;
}
.btnFeedAnnullato {
  background-color: #ff7525;
}
.divBtnInviaSM {
  max-width: 100%;

  font-weight: bolder;
  letter-spacing: 0.1em;
  color: #1a085a;
  min-height: 20vh;
}
.divBtnInviaSM h2 {
  font-size: calc(22px + 2vmin);
  margin-bottom: 0px !important;
}
.form-check-label {
  color: #1a085a !important;
}
.btn-primary:not(:disabled):not(.disabled) {
  color: #1a085a !important;
}
#btnInvia {
  width: auto;
  padding-left: 100px;
  padding-right: 100px;
}

.footerRowSM {
  color: #1a085a;
  padding-top: 10px;
  /*min-height: 12vh;*/
  justify-content: center;
  align-items: center;
  background-color: #8bcac8;
}
.footerCardSM {
  color: #1a085a;
  padding-top: 1px;
  min-height: 12vh;
  justify-content: center;
  align-items: center;
  background-color: #8bcac8;
}
.footerRowSM .card:after {
  content: "";
  display: block;
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.footerCardSM h5 {
  font-size: calc(9px + 2vmin);
  font-weight: bold;
  letter-spacing: 0.015em;
}
.footerCardSM p {
  font-size: calc(7px + 1vmin) !important;
  font-weight: normal;
  margin-bottom: 0.3rem;
}
div.formRichiesta {
  color: #1a085a;
}
.footerRowSM a {
  color: #1a085a;
}
.headerCardSM:after {
  content: "";
  display: block;
  margin-top: 0px;
  margin-left: 0px;
}

.textButtonsSM {
  background-color: #8bcac8 !important;
  font-size: calc(15px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.showbox {
  padding: 0;
  position: relative;
}

.testoDestraImmagine {
  position: absolute;
}

.formTitle {
  font-weight: bold;
}

input.form-control {
  border-bottom: 3px solid #8bcac8;
  color: #1a085a;
}

div.form-group label {
  color: #1a085a;
}
div.bootstrap-select-wrapper label {
  color: #1a085a;
}

.textAlignLeft {
  text-align: left;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.showbox {
  padding: 0;
  position: relative;
}

.testoDestraImmagine {
  position: absolute;
}

.formTitle {
  font-weight: bold;
}

input.form-control {
  border-bottom: 3px solid #8bcac8 !important;
  color: #1a085a;
}

div.form-group label {
  color: #1a085a;
}
div.bootstrap-select-wrapper label {
  color: #1a085a;
}

.textAlignLeft {
  text-align: left;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.cookiebar .cookiebar-btn:last-child {
  margin-left: 15px;
}
